import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ScrollToTop from 'react-scroll-to-top';

import { BLOG_KEYWORDS } from 'globals';
import { getPageInfo, getAuthorCategories } from 'utils';

import { ScrollTopIcon } from 'icons';
import { SEO, Layout, AuthorPreview, CategoryMenuBlog, Pagination } from 'components';
import { BlogList } from 'components/Blog';
import { BeInTouch } from 'components/Paragraphs/Types';

const AuthorCategory = ({
  pageContext: { currentPage, numPages, alias, allAvailableAuthorCategoriesId, categoryName },
  location: { pathname: siteUrl },
  location,
  data: {
    allNodeBlog: { edges: posts },
    allTaxonomyTermBlogCategory: { edges: category },
    taxonomyTermAuthor: { name },
    taxonomyTermAuthor,
  },
}) => {
  const {
    paginationPath,
    isFirst,
    isLast,
    prevPage,
    nextPage,
    metaTitle,
    metaDescription,
  } = getPageInfo(location.pathname, currentPage, numPages, categoryName, true);
  const authorCategories = getAuthorCategories(allAvailableAuthorCategoriesId, category);

  return (
    <Layout
      type="blog"
      nodeTitle={name}
      isDefaultBreadcrumbs
      parentBreadcrumb="/blog/"
      parentTitle="Blog"
    >
      <SEO
        title={metaTitle || name}
        description={metaDescription || ''}
        keywords={BLOG_KEYWORDS || ''}
        currentUrl={siteUrl}
        robots="noindex, nofollow"
      />

      <AuthorPreview author={taxonomyTermAuthor} />
      <div className="page-top">
        <CategoryMenuBlog categories={authorCategories} additionalQuery={alias} />
      </div>

      <BlogList posts={posts} />
      <Pagination
        isFirst={isFirst}
        numPages={numPages}
        currentPage={currentPage}
        isLast={isLast}
        nextPage={nextPage}
        prevPage={prevPage}
        paginationPath={paginationPath}
      />
      <BeInTouch title="Want to work with us?" buttonTitle="Let’s Talk" blueBg="blue-bg" />
      <ScrollToTop smooth className="scroll-top-button" component={<ScrollTopIcon />} />
    </Layout>
  );
};

AuthorCategory.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    allNodeBlog: PropTypes.object,
    allTaxonomyTermBlogCategory: PropTypes.object,
    taxonomyTermAuthor: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default AuthorCategory;

export const query = graphql`
  query AuthorCategoryPageQuery(
    $drupal_id: String!
    $drupal_internal__revision_id: Int!
    $categoryId: Int!
    $skip: Int!
    $limit: Int!
  ) {
    taxonomyTermAuthor(drupal_id: { eq: $drupal_id }) {
      ...TaxonomyTermAuthor
    }

    allNodeBlog(
      filter: {
        field_article_author: {
          elemMatch: { drupal_internal__target_id: { eq: $drupal_internal__revision_id } }
        }
        relationships: {
          field_blog_category: { elemMatch: { drupal_internal__tid: { eq: $categoryId } } }
        }
      }
      sort: { fields: [created], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      ...AllBlog
    }

    allTaxonomyTermBlogCategory {
      ...AllTaxonomyTermBlogCategory
    }
  }
`;
